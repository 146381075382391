import React from 'react';
import { Button, Grid } from '@mui/material';
import Sidebar from '../components/Sidebar';
import { useNavigate } from 'react-router-dom';

function Report() {
	let navigate = useNavigate();
	const handleOpenFreightReport = () => {
		navigate('/freightreport');
	};
	const handleOpenConsignmentFreightReport = () => {
		navigate('/consignmentfreightreport');
	};

	return (
		<Grid container>
			<Grid id="sidebarContainer" item md={2}>
				<Sidebar />
			</Grid>
			<Grid item container md={10} sx={{ px: 5, my: 5 }}>
				<Grid item md={6} sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
					<Button sx={{ width: '90%' }} variant="contained" onClick={handleOpenFreightReport}>
						Customers Freight Report
					</Button>
				</Grid>
				<Grid item md={6} sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
					<Button sx={{ width: '90%' }} variant="contained" onClick={handleOpenConsignmentFreightReport}>
						Consignment Freight Report
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default Report;
