import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

function AddFreightForm(props) {
	const [usernameEntered, setUsernameEntered] = React.useState('');
	const [selectedUsername, setSelectedUsername] = React.useState('');
	const [selectedWeight, setSelectedWeight] = React.useState('');
	const [selectedCBM, setSelectedCBM] = React.useState('');
	const [selectedConsignmentID, setSelectedConsignmentID] = React.useState('');
	const [totalWeightCost, setTotalWeightCost] = React.useState(0);
	const [totalCBMCost, setTotalCBMCost] = React.useState(0);
	const [filteredData, setFilteredData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [openConsignment, setOpenConsignment] = React.useState([]);
	const [consignmentData, setConsignmentData] = React.useState(null);
	const token = sessionStorage.getItem('Token');

	const { open, onClose, users } = props;
	const onSelectUsernameFromListHandler = (selectedUsername) => {
		setSelectedUsername(selectedUsername);
		setFilteredData([]);
	};

	const handleConsignmentChange = (e) => {
		const selectedConsignmentValue = e.target.value;
		setSelectedConsignmentID(selectedConsignmentValue);
		formik.setFieldValue('selectedConsignment', selectedConsignmentValue);

		if (formik.values.selectedConsignment && !selectedConsignmentValue) {
			formik.setFieldValue('freightCost', '');
		}
		formik.handleChange(e);
	};
	console.log(selectedConsignmentID, 'consignmentData', consignmentData);

	const PostFreight = async (values) => {
		const freightPaymentValue = {
			username: values.username,
			total_cost: values.freightCost,
			total_weight: selectedWeight || 0,
			total_cbm: selectedCBM || 0,
			goods_desc: values.description,
			payment_id: {
				status: 'invalid',
				payment_mode: 'invalid',
				balance: 0,
				dept: 0,
				transaction_type: 'Freight',
			},
			selectedConsignment: values.selectedConsignment,
		};

		try {
			// const response = await axios.post('http://127.0.0.1:8000/api/customerFreightView/', freightPaymentValue, {
			// 	headers: {
			// 		Authorization: `Token ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// });
			const response = await axios.post(
				'https://rockmanapi.nexlogssolutions.com/api/customerFreightView/',
				freightPaymentValue,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			setLoading(false);
			alert('Freight Added Successfully');
		} catch (err) {
			setLoading(false);
			alert('Error: System failed to add freight, please contact your administrator');
		}
	};

	const formik = useFormik({
		initialValues: {
			username: '',
			totalWeight: '',
			totalCBM: '',
			freightCost: '',
			description: '',
			selectedConsignment: '',
			totalWeightCost: 0,
			totalCBMCost: 0,
		},
		validationSchema: Yup.object({
			username: Yup.string().max(15, 'Must be 15 characters or less'),
		}),
		onSubmit: (values, { resetForm }) => {
			values.username = selectedUsername;
			setLoading(true);
			PostFreight(values);
			resetForm();
		},
	});

	React.useEffect(() => {
		axios
			// .get('http://127.0.0.1:8000/api/consignmentlist/', {
			.get('https://rockmanapi.nexlogssolutions.com/api/consignmentlist/', {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'application/json',
				},
			})
			.then((res) => {
				setOpenConsignment(res.data);
			})
			.catch(() => {
				alert('Consignment not fetched! Contact your administrator.');
			});
	}, []);

	React.useEffect(() => {
		const fetchData = async () => {
			if (selectedConsignmentID !== '') {
				try {
					const response = await axios.get(
						// `http://127.0.0.1:8000/api/consignmentbyid/${selectedConsignmentID}/`,
						`https://rockmanapi.nexlogssolutions.com/api/consignmentbyid/${selectedConsignmentID}/`,
						{
							headers: {
								Authorization: `Token ${token}`,
								'Content-Type': 'application/json',
							},
						}
					);
					setConsignmentData(response.data);
				} catch (error) {
					console.error('Error fetching consignment data:', error);
				}
			}
		};
		fetchData();
	}, [selectedConsignmentID]);

	React.useEffect(() => {
		if (consignmentData) {
			const cost = consignmentData.shipping_mode === 'air' ? totalWeightCost : totalCBMCost;
			formik.setFieldValue('freightCost', cost);
		}
	}, [totalWeightCost, totalCBMCost, consignmentData]);

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle
				sx={{ textAlign: 'center', fontSize: { xs: 'large', sm: 'xx-large' }, paddingBottom: '1.5rem' }}
			>
				Add Freight Payment
			</DialogTitle>
			<DialogContent>
				<Box>
					<form onSubmit={formik.handleSubmit}>
						<Grid container spacing={2}>
							{/* Username Field */}
							<Grid item sm={6}>
								<TextField
									size="small"
									margin="dense"
									id="username"
									label="Username"
									fullWidth
									variant="outlined"
									value={selectedUsername}
									onChange={(e) => {
										const searchWord = e.target.value;
										setUsernameEntered(searchWord);
										setSelectedUsername(searchWord);
										const newFilter = users.filter((value) => {
											return value.user_id.username
												.toLowerCase()
												.includes(searchWord.toLowerCase());
										});
										setFilteredData(searchWord ? newFilter : []);
									}}
								/>
								{filteredData.length > 0 && (
									<div className="dataResult">
										{filteredData.slice(0, 10).map((value, key) => (
											<div
												key={key}
												onClick={() => onSelectUsernameFromListHandler(value.user_id.username)}
											>
												<p>{value.user_id.username}</p>
											</div>
										))}
									</div>
								)}
							</Grid>

							{/* Consignment Selection */}
							<Grid item xs={12} md={6}>
								<TextField
									select
									id="selectedConsignment"
									name="selectedConsignment"
									label="Consignment number"
									value={formik.values.selectedConsignment}
									onChange={handleConsignmentChange}
									fullWidth
									variant="outlined"
									size="small"
								>
									{openConsignment.map((consignment) => (
										<MenuItem key={consignment.id} value={consignment.id}>
											{consignment.name}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							{consignmentData && (
								<>
									{consignmentData.shipping_mode === 'air' ? (
										<Grid item sm={6}>
											<TextField
												size="small"
												margin="dense"
												id="totalWeight"
												label="Total Weight"
												fullWidth
												variant="outlined"
												value={selectedWeight}
												onChange={(e) => {
													const enteredWeight = e.target.value;
													setSelectedWeight(enteredWeight);
													setTotalWeightCost(enteredWeight * consignmentData.price_per_kilo);
												}}
											/>
										</Grid>
									) : (
										<Grid item sm={6}>
											<TextField
												size="small"
												margin="dense"
												id="totalCBM"
												label="Total CBM"
												fullWidth
												variant="outlined"
												value={selectedCBM}
												onChange={(e) => {
													const enteredCBM = e.target.value;
													setSelectedCBM(enteredCBM);
													setTotalCBMCost(enteredCBM * consignmentData.price_per_cbm);
												}}
											/>
										</Grid>
									)}
									<Grid item sm={6}>
										<TextField
											size="small"
											margin="dense"
											id="freightCost"
											label="Freight Cost"
											fullWidth
											variant="outlined"
											disabled
											value={formik.values.freightCost}
										/>
									</Grid>
								</>
							)}

							<Grid item sm={12}>
								<TextField
									multiline
									rows={4}
									size="small"
									margin="dense"
									id="description"
									label="Description"
									fullWidth
									variant="outlined"
									value={formik.values.description}
									onChange={formik.handleChange}
								/>
							</Grid>

							{/* Action Buttons */}
							<Grid item xs={12} sx={{ textAlign: 'center', paddingTop: '1rem' }}>
								<Button variant="outlined" color="error" onClick={onClose} sx={{ marginRight: '1rem' }}>
									Cancel
								</Button>
								<Button type="submit" variant="contained" color="success">
									{loading ? <CircularProgress size={24} /> : 'Add Freight'}
								</Button>
							</Grid>
						</Grid>
					</form>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default AddFreightForm;
