import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';

const PasswordReset = () => {
	const { uidb64, token } = useParams();
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState('');
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await axios.post(
				// `http://127.0.0.1:8000/api/password-reset/${uidb64}/${token}/`,
				`https://rockmanapi.nexlogssolutions.com/api/password-reset/${uidb64}/${token}/`,
				{ password }
			);
			setMessage(response.data.message);

			if (response.data.message === 'Password reset successful') {
				// Redirect to login page or any other page
				navigate('/login');
			}
		} catch (error) {
			setMessage(error.response?.data?.message || 'An error occurred.');
		}
	};

	return (
		<Container maxWidth="sm">
			<Box sx={{ mt: 4, p: 3, boxShadow: 3, borderRadius: 2 }}>
				<Typography variant="h4" gutterBottom>
					Reset Password
				</Typography>
				{message && (
					<Alert severity={message.includes('successful') ? 'success' : 'error'} sx={{ mb: 2 }}>
						{message}
					</Alert>
				)}
				<form onSubmit={handleSubmit}>
					<TextField
						label="New Password"
						variant="outlined"
						fullWidth
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
						sx={{ mb: 3 }}
					/>
					<Button variant="contained" color="primary" type="submit" fullWidth>
						Reset Password
					</Button>
				</form>
			</Box>
		</Container>
	);
};

export default PasswordReset;
