import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';

function UpdateFreightEdit(props) {
	const { open, close, currentFreight, selectedConsignmentID, onClose } = props;
	const [loading, setLoading] = useState(false);
	const [consignmentData, setConsignmentData] = useState(null);
	const token = sessionStorage.getItem('Token');


	const handleBankChange = (e) => {
		const bankValue = e.target.value;

		// Check if the bank field was filled and then removed
		if (formik.values.bank && !bankValue) {
			// Clear the verifyBank field
			formik.setFieldValue('verifyBank', '');
			formik.setFieldValue('bankCurrency', '');
		}

		// Update the bank field
		formik.handleChange(e);
	};

	const handleCashChange = (e) => {
		const bankValue = e.target.value;

		// Check if the bank field was filled and then removed
		if (formik.values.cash && !bankValue) {
			// Clear the verifyBank field
			formik.setFieldValue('verifyCash', '');
			formik.setFieldValue('cashCurrency', '');
		}

		// Update the bank field
		formik.handleChange(e);
	};

	const handleMomoChange = (e) => {
		const bankValue = e.target.value;

		// Check if the bank field was filled and then removed
		if (formik.values.momo && !bankValue) {
			// Clear the verifyBank field
			formik.setFieldValue('verifyMomo', '');
			formik.setFieldValue('momoCurrency', '');
		}

		// Update the bank field
		formik.handleChange(e);
	};
	const updateWithNoteValue = async (values) => {
		setLoading(true);

		try {
			const response = await axios.put(
				'https://rockmanapi.nexlogssolutions.com/api/customerfreightUpdateNote/' + currentFreight.id + '/',
				values,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			// const response = await axios.put(
			// 	'http://127.0.0.1:8000/api/customerfreightUpdateNote/' + currentFreight.id + '/',
			// 	values,
			// 	{
			// 		headers: {
			// 			Authorization: `Token ${token}`,
			// 			'Content-Type': 'application/json',
			// 		},
			// 	}
			// );
			if (response.data.StatusCode == '99') {
				alert(response.data.StatusMessage);
			} else {
				formik.resetForm();
				setLoading(false);
				alert('Freight record successfully updated');
				onClose();
			}
		} catch (err) {
			// Handle Error Here
			throw err.message;
			console.error(err.message);
		}
	};

	let initials = {
		username: currentFreight ? currentFreight?.customer_id?.user_id.username : '',
		totalWeight: currentFreight ? currentFreight.total_weight : '',
		totalCBM: currentFreight ? currentFreight.total_cbm : '',
		description: currentFreight ? currentFreight.goods_desc : '',
		note: currentFreight.note ? currentFreight.note : '',
		cash: '',
		cash_cedis: '',
		momo_cedis: '',
		bank_cedis: '',
		bank: '',
		verify_cashCedis: '',
		verify_momo: '',
		verify_cash: '',
		verify_bank: '',
		verify_bankCedis: '',
		packageNumber: currentFreight.number_of_package ? currentFreight.number_of_package : 0,
		freightCost: currentFreight ? currentFreight.total_cost : '',
		selectedConsignmentIDVal: selectedConsignmentID,
	};

	const formik = useFormik({
		initialValues: initials,
		enableReinitialize: true,

		onSubmit: (values) => {
			updateWithNoteValue(values);
		},
	});
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				// Check if selectedConsignmentID is not an empty string
				if (selectedConsignmentID !== '') {
					const response = await axios.get(
						`https://rockmanapi.nexlogssolutions.com/api/consignmentbyid/${selectedConsignmentID}/`,
						{
							headers: {
								Authorization: `Token ${token}`,
								'Content-Type': 'application/json',
							},
						}
					);
					// const response = await axios.get(
					// 	`http://127.0.0.1:8000/api/consignmentbyid/${selectedConsignmentID}/`,
					// 	{
					// 		headers: {
					// 			Authorization: `Token ${token}`,
					// 			'Content-Type': 'application/json',
					// 		},
					// 	}
					// );
					setConsignmentData(response.data);
				}
			} catch (error) {
				console.error('Error fetching consignment data:', error);
			}
		};

		fetchData();
	}, [selectedConsignmentID]);

	return (
		<Dialog open={open} onClose={close} sx={{ '& .MuiPaper-root': { width: '100%' } }}>
			<DialogContent>
				<Box style={{ paddingTop: 5 }}>
					<form onSubmit={formik.handleSubmit}>
						<Grid container spacing={2}>
							<Grid item sm={6}>
								<TextField
									size="small"
									margin="dense"
									id="username"
									label="Username"
									fullWidth
									variant="outlined"
									value={formik.values.username}
									disabled
								/>
							</Grid>
							<Grid item sm={6}>
								{consignmentData && consignmentData.shipping_mode == 'air' ? (
									<TextField
										type="number"
										size="small"
										margin="dense"
										id="totalWeight"
										label="Total Weight"
										fullWidth
										variant="outlined"
										value={formik.values.totalWeight}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.totalWeight && formik.errors.totalWeight}
									/>
								) : (
									<TextField
										type="number"
										size="small"
										margin="dense"
										id="totalCBM"
										label="totalCBM"
										fullWidth
										variant="outlined"
										value={formik.values.totalCBM}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.totalCBM && formik.errors.totalCBM}
									/>
								)}
							</Grid>
							<Grid item sm={6} sx={{ paddingTop: '10px !important' }}>
								{consignmentData && consignmentData.shipping_mode == 'air' ? (
									<TextField
										size="small"
										margin="dense"
										id="freightCost"
										label="Freight Cost"
										fullWidth
										variant="outlined"
										disabled
										value={formik.values.totalWeight * 11}
									/>
								) : (
									<TextField
										size="small"
										margin="dense"
										id="freightCost"
										label="Freight Cost"
										fullWidth
										variant="outlined"
										disabled
										value={formik.values.totalCBM * 300}
										onChange={formik.handleChange}
									/>
								)}
							</Grid>
							<Grid item sm={6} sx={{ paddingTop: '10px !important' }}>
								<TextField
									size="small"
									margin="dense"
									id="packageNumber"
									label="Package Number"
									fullWidth
									variant="outlined"
									type="number"
									value={formik.values.packageNumber}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.packageNumber && formik.errors.packageNumber}
								/>
							</Grid>
							<Grid item sm={6}></Grid>
							<Grid item md={12} sx={{ paddingTop: '5px !important', mt: { xs: 1, sm: 'unset' } }}>
								<Typography> payment Mode</Typography>
							</Grid>
							<Grid item md={6} sx={{ paddingTop: '10px !important', mt: 1 }}>
								<TextField
									size="small"
									id="cash"
									label="Cash Dollar"
									fullWidth
									variant="outlined"
									value={formik.values.cash}
									onChange={handleCashChange}
									onBlur={formik.handleBlur}
									error={formik.touched.cash && formik.errors.cash}
								/>
							</Grid>

							<Grid item md={6} sx={{ mt: 1, pl: 2, paddingTop: '10px !important' }}>
								{formik.values.cash && (
									<TextField
										size="small"
										id="verify_cash"
										label="verification Code"
										fullWidth
										variant="outlined"
										value={formik.values.verify_cash}
										onChange={formik.handleChange}
									/>
								)}
							</Grid>
							<Grid item md={6} sx={{ mt: 1, paddingTop: '10px !important' }}>
								<TextField
									size="small"
									id="cash_cedis"
									label="Cash Cedis"
									fullWidth
									variant="outlined"
									value={formik.values.cash_cedis}
									onChange={handleCashChange}
									onBlur={formik.handleBlur}
									error={formik.touched.cash_cedis && formik.errors.cash_cedis}
								/>
							</Grid>

							<Grid item md={6} sx={{ mt: 1, pl: 2, paddingTop: '10px !important' }}>
								{formik.values.cash_cedis && (
									<TextField
										size="small"
										id="verify_cashCedis"
										label="verification Code"
										fullWidth
										variant="outlined"
										value={formik.values.verify_cashCedis}
										onChange={formik.handleChange}
									/>
								)}
							</Grid>
							<Grid item md={6} sx={{ mt: 1, paddingTop: '10px !important' }}>
								<TextField
									size="small"
									id="momo_cedis"
									label="Momo Cedis"
									fullWidth
									variant="outlined"
									value={formik.values.momo_cedis}
									onChange={handleMomoChange}
									onBlur={formik.handleBlur}
									error={formik.touched.momo_cedis && formik.errors.momo_cedis}
								/>
							</Grid>
							<Grid item md={6} sx={{ mt: 1, pl: 2, paddingTop: '10px !important' }}>
								{formik.values.momo_cedis && (
									<TextField
										size="small"
										id="verify_momo"
										label="Momo verification Code"
										fullWidth
										variant="outlined"
										value={formik.values.verify_momo}
										onChange={formik.handleChange}
									/>
								)}
							</Grid>
							<Grid item md={6} sx={{ mt: 1, paddingTop: '10px !important' }}>
								<TextField
									size="small"
									id="bank"
									label="Bank Dollar"
									fullWidth
									variant="outlined"
									value={formik.values.bank}
									onChange={handleBankChange}
									onBlur={formik.handleBlur}
									error={formik.touched.bank && formik.errors.bank}
								/>
							</Grid>
							<Grid item md={6} sx={{ mt: 1, pl: 2, paddingTop: '10px !important' }}>
								{formik.values.bank && (
									<TextField
										size="small"
										id="verify_bank"
										label=" verification Code"
										fullWidth
										variant="outlined"
										value={formik.values.verify_bank}
										onChange={formik.handleChange}
									/>
								)}
							</Grid>
							<Grid item md={6} sx={{ mt: 1, paddingTop: '10px !important' }}>
								<TextField
									size="small"
									id="bank_cedis"
									label="Bank Cedis"
									fullWidth
									variant="outlined"
									value={formik.values.bank_cedis}
									onChange={handleBankChange}
									onBlur={formik.handleBlur}
									error={formik.touched.bank_cedis && formik.errors.bank_cedis}
								/>
							</Grid>

							<Grid item md={6} sx={{ mt: 1, pl: 2, paddingTop: '10px !important' }}>
								{formik.values.bank_cedis && (
									<TextField
										size="small"
										id="verify_bankCedis"
										label="verification Code"
										fullWidth
										variant="outlined"
										value={formik.values.verify_bankCedis}
										onChange={formik.handleChange}
									/>
								)}
							</Grid>
							<Grid item sm={12}>
								<TextField
									multiline
									rows={4}
									size="small"
									margin="dense"
									id="description"
									label="Description"
									fullWidth
									variant="outlined"
									value={formik.values.description}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.description && formik.errors.description}
								/>
							</Grid>
							<Grid item sm={12}>
								<TextField
									multiline
									rows={4}
									size="small"
									margin="dense"
									id="note"
									label="Note"
									fullWidth
									variant="outlined"
									value={formik.values.note}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.note && formik.errors.note}
								/>
							</Grid>
						</Grid>

						<Grid item>
							<Box
								sx={{
									gap: { xs: '1rem', sm: '2rem' },
									display: 'flex',
									justifyContent: 'center !important',
									paddingBlock: '1rem',
								}}
							>
								<Button sx={{ width: '40%' }} variant="contained" onClick={close}>
									Cancel
								</Button>
								<Button
									sx={{ width: '40%', marginLeft: '0 !important' }}
									variant="contained"
									type="submit"
								>
									Submit
								</Button>
								{loading ? <CircularProgress sx={{ position: 'absolute', top: '50%' }} /> : null}
							</Box>
						</Grid>
					</form>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default UpdateFreightEdit;
