import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';

const PasswordResetRequest = () => {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			// const response = await axios.post('http://127.0.0.1:8000/api/password-reset-request/', { email });
			const response = await axios.post('https://rockmanapi.nexlogssolutions.com/api/password-reset-request/', { email });
			setMessage(response.data.message);
		} catch (error) {
			setMessage(error.response?.data?.message || 'An error occurred.');
		}
	};

	return (
		<Container maxWidth="sm">
			<Box sx={{ mt: 4, p: 3, boxShadow: 3, borderRadius: 2 }}>
				<Typography variant="h5" gutterBottom align="center" sx={{ fontSize: { xs: '1.2rem' } }}>
					Password Reset Request
				</Typography>
				{message && (
					<Alert
						severity={message.includes('Password reset email sent') ? 'success' : 'error'}
						sx={{ mb: 2 }}
					>
						{message}
					</Alert>
				)}
				<form onSubmit={handleSubmit}>
					<TextField
						label="Email Address"
						variant="outlined"
						fullWidth
						size="small"
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
						sx={{ mb: 3 }}
					/>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						fullWidth
						sx={{ fontSize: { xs: '0.7rem', sm: '0.875rem', md: '1rem' } }}
					>
						Request Password Reset
					</Button>
				</form>
			</Box>
		</Container>
	);
};

export default PasswordResetRequest;
