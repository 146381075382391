import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function AddFreightCreditForm(props) {
	const [usernameEntered, setUsernameEntered] = React.useState('');
	const { open, onClose, users, setOpenTP } = props;
	const [filteredData, setFilteredData] = React.useState([]);
	const [selectedUsername, setSelectedUsername] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const token = sessionStorage.getItem('Token');
	const staffUser = sessionStorage.getItem('User');
	const staffUserObject = JSON.parse(staffUser);

	const onSelectUsernameFromListHandler = (selectedUsername) => {
		setSelectedUsername(selectedUsername);
		setFilteredData([]);
	};

	const PostTransfer = async (values) => {

		const transferValue = {
			username: values.username,
			transfer_type: 'Freight',
			details: values.details,
			cash: values.cash !== '' ? values.cash : 0,
			cashCedis: values.cashCedis !== '' ? values.cashCedis : 0,
			momo: values.momo !== '' ? values.momo : 0,
			bankCedis: values.bankCedis !== '' ? values.bankCedis : 0,
			bank: values.bank !== '' ? values.bank : 0,
			verify_momo: values.verify_momo,
			verify_cashCedis: values.verify_cashCedis,
			verify_cash: values.verify_cash,
			verify_bankCedis: values.verify_bankCedis,
			verify_bank: values.verify_bank,
			staff_user:staffUserObject.username	
		};

		console.log('transferValue', transferValue)

		try {
			const response = await axios.post(
				'https://rockmanapi.nexlogssolutions.com/api/customertranfer/',
				transferValue,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			// const response = await axios.post('http://127.0.0.1:8000/api/customertranfer/', transferValue,
			// {
			// 	headers: {
			// 		Authorization: `Token ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// });
			if (response.status == 200) {
				// setOpenTP(true);
				setLoading(false);
				alert('Freight prepayment successfully and credited to customer total balance');
			}
			// setAlert(response.status);
		} catch (err) {
			alert('Freight prepayment failed, contact your system administrator');
		}
	};

	const handleBankChange = (e) => {
		const bankValue = e.target.value;

		// Check if the bank field was filled and then removed
		if (formik.values.bank && !bankValue) {
			// Clear the verifyBank field
			formik.setFieldValue('verifyBank', '');
		}

		// Update the bank field
		formik.handleChange(e);
	};

	const handleCashChange = (e) => {
		const bankValue = e.target.value;

		// Check if the bank field was filled and then removed
		if (formik.values.cash && !bankValue) {
			// Clear the verifyBank field
			formik.setFieldValue('verifyCash', '');
		}

		// Update the bank field
		formik.handleChange(e);
	};

	const handleMomoChange = (e) => {
		const bankValue = e.target.value;

		// Check if the bank field was filled and then removed
		if (formik.values.momo && !bankValue) {
			// Clear the verifyBank field
			formik.setFieldValue('verifyMomo', '');
		}

		// Update the bank field
		formik.handleChange(e);
	};

	const formik = useFormik({
		initialValues: {
			username: selectedUsername,
			// transfer_type: '',
			cash: '',
			cashCedis: '',
			momo: '',
			bankCedis: '',
			bank: '',
			details: '',
			verify_momo: '',
			verify_cash: '',
			verify_cashCedis: '',
			verify_bank: '',
			verify_bankCedis: '',
		},
		validationSchema: Yup.object({
			username: Yup.string().max(15, 'Must be 15 characters or less'),
			// amountInDollars: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
			cash: Yup.number().nullable(),
			momo: Yup.number().nullable(),
			bank: Yup.number().nullable(),
			details: Yup.string(),
		}),
		onSubmit: (values, { resetForm }) => {
			console.log('values', values);
			values.username = selectedUsername;
			setLoading(true);
			PostTransfer(values);
			resetForm();
		},
	});

	return (
		<>
			<Dialog open={open} onClose={onClose}>
				<DialogTitle sx={{ textAlign: 'center', fontSize: {sx:'x-large', sm:'xx-large'}, paddingBottom: '1.5rem' }}>
					Prepayment
				</DialogTitle>
				<DialogContent sx={{ padding: 0 }}>
					<Box>
						<form onSubmit={formik.handleSubmit}>
							<Grid
								container
								// spacing={2}
								sx={{ display: 'flex', justifyContent: 'center', margin: 0, px: {xs:2, sm:8}, width: '100%' }}
							>
								<Grid item md={6} sx={{ paddingLeft: '0 !important' }}>
									<TextField
										// sx={{width:'100%'}}
										size="small"
										margin="dense"
										id="username"
										label="Username"
										fullWidth
										variant="outlined"
										value={selectedUsername}
										onChange={(e) => {
											const searchWord = e.target.value;
											setUsernameEntered(searchWord);
											setSelectedUsername(searchWord);
											const newFilter = users.filter((value) => {
												return value.user_id.username
													.toLowerCase()
													.includes(searchWord.toLowerCase());
											});
											if (searchWord === '') {
												setFilteredData([]);
											} else {
												setFilteredData(newFilter);
											}
										}}
									/>
									{filteredData.length !== 0 && (
										<div className="dataResult">
											{filteredData.slice(0, 10).map((value, key) => (
												<div
													key={key}
													onClick={() => {
														onSelectUsernameFromListHandler(value.user_id.username);
													}}
												>
													<p>{value.user_id.username} </p>
												</div>
											))}
										</div>
									)}
								</Grid>
								<Grid item md={6} sx={{ pl: 2 }}></Grid>
								<Grid item md={12} sx={{ mt: {xs:1, sm:4} }}>
									<Typography> payment Mode</Typography>
								</Grid>
								<Grid item md={4} sx={{ mt: 1 }}>
									<TextField
										size="small"
										id="cash"
										label="Cash"
										fullWidth
										variant="outlined"
										value={formik.values.cash}
										onChange={handleCashChange}
										onBlur={formik.handleBlur}
										error={formik.touched.cash && formik.errors.cash}
									/>
								</Grid>
								<Grid
									item
									md={4}
									sx={{ mt: 1, pl: 2, textAlign: 'center', display: 'flex', alignItems: 'center' }}
								>
									{formik.values.cash && (
										<Select
											id="cashCurrency"
											name="cashCurrency"
											value={formik.values.cashCurrency}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.cashCurrency && Boolean(formik.errors.cashCurrency)}
											displayEmpty
											inputProps={{ 'aria-label': 'Select Currency' }}
											sx={{ width: '100%' }}
											size="small"
										>
											<MenuItem value="" disabled>
												Select Currency
											</MenuItem>
											<MenuItem value="cedis">Cedis</MenuItem>
											<MenuItem value="dollar">Dollar</MenuItem>
										</Select>
									)}
								</Grid>
								<Grid item md={4} sx={{ mt: 1, pl: 2 }}>
									{formik.values.cash && (
										<TextField
											size="small"
											id="verifyCash"
											label="Cash verification Code"
											fullWidth
											variant="outlined"
											value={formik.values.verifyCash}
											onChange={formik.handleChange}
										/>
									)}
								</Grid>
								<Grid item md={4} sx={{ mt: 1 }}>
									<TextField
										size="small"
										id="momo"
										label="Momo"
										fullWidth
										variant="outlined"
										value={formik.values.momo}
										onChange={handleMomoChange}
										onBlur={formik.handleBlur}
										error={formik.touched.momo && formik.errors.momo}
									/>
								</Grid>
								<Grid
									item
									md={4}
									sx={{ mt: 1, pl: 2, textAlign: 'center', display: 'flex', alignItems: 'center' }}
								>
									{formik.values.momo && (
										<Select
											id="momoCurrency"
											name="momoCurrency"
											value={formik.values.momoCurrency}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.momoCurrency && Boolean(formik.errors.momoCurrency)}
											displayEmpty
											inputProps={{ 'aria-label': 'Select Currency' }}
											sx={{ width: '100%' }}
											size="small"
											disabled
											displayEmpty
										>
											<MenuItem value="" disabled>
												Select Currency
											</MenuItem>
											<MenuItem value="cedis">Cedis</MenuItem>
											<MenuItem value="dollar">Dollar</MenuItem>
										</Select>
									)}
								</Grid>
								<Grid item md={4} sx={{ mt: 1, pl: 2 }}>
									{formik.values.momo && (
										<TextField
											size="small"
											id="verifyMomo"
											label="Momo verification Code"
											fullWidth
											variant="outlined"
											value={formik.values.verifyMomo}
											onChange={formik.handleChange}
										/>
									)}
								</Grid>
								<Grid item md={4} sx={{ mt: 1 }}>
									<TextField
										size="small"
										id="bank"
										label="Bank"
										fullWidth
										variant="outlined"
										value={formik.values.bank}
										onChange={handleBankChange}
										onBlur={formik.handleBlur}
										error={formik.touched.bank && formik.errors.bank}
									/>
								</Grid>
								<Grid
									item
									md={4}
									sx={{ mt: 1, pl: 2, textAlign: 'center', display: 'flex', alignItems: 'center' }}
								>
									{formik.values.bank && (
										<Select
											id="bankCurrency"
											name="bankCurrency"
											value={formik.values.bankCurrency}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.bankCurrency && Boolean(formik.errors.bankCurrency)}
											displayEmpty
											inputProps={{ 'aria-label': 'Select Currency' }}
											sx={{ width: '100%' }}
											size="small"
										>
											<MenuItem value="" disabled>
												Select Currency
											</MenuItem>
											<MenuItem value="cedis">Cedis</MenuItem>
											<MenuItem value="dollar">Dollar</MenuItem>
										</Select>
									)}
								</Grid>
								<Grid item md={4} sx={{ mt: 1, pl: 2 }}>
									{formik.values.bank && (
										<TextField
											size="small"
											id="verifyBank"
											label="Bank verification Code"
											fullWidth
											variant="outlined"
											value={formik.values.verifyBank}
											onChange={formik.handleChange}
										/>
									)}
								</Grid>
								<Grid item md={12} sx={{ paddingLeft: '0 !important', mt: 2 }}>
									<TextField
										size="small"
										margin="dense"
										id="details"
										label="Transfer details"
										fullWidth
										multiline
										rows={4}
										variant="outlined"
										value={formik.values.details}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.details && formik.errors.details}
									/>
								</Grid>
								<Box
									sx={{
										gap: {xs:'1rem', sm:'3rem'},
										display: 'flex',
										justifyContent: 'center !important',
										paddingBlock: '1rem',
										width: '100%',
										marginBottom: '1rem',
										marginTop: '.5rem',
									}}
								>
									<Button sx={{ width: '43%', height: {xs:'2rem', sm:'3rem'} }} variant="contained" onClick={onClose}>
										Cancel
									</Button>
									<Button sx={{ width: '43%', height: {xs:'2rem', sm:'3rem'} }} variant="contained" type="submit">
										Submit
									</Button>
								</Box>
								{loading ? <CircularProgress sx={{ position: 'absolute', top: '50%' }} /> : null}
							</Grid>
						</form>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default AddFreightCreditForm;
