import React, { useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Divider } from '@mui/material';

const Register = () => {
	// const { auth, setAuth } = useContext(AuthContext);
	const navigate = useNavigate();
	const token = sessionStorage.getItem('Token');

	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
			confirm_password: '',
			email: '',
			phone_no: '',
			first_name: '',
			last_name: '',
		},
		validationSchema: Yup.object({
			username: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
			password: Yup.string().required('Required'),
			confirm_password: Yup.string()
				.oneOf([Yup.ref('password'), null], 'Passwords must match')
				.required('Required'),
			email: Yup.string().email('Invalid email address').required('Required'),
			phone_no: Yup.string().required('Required'),
			first_name: Yup.string().required('Required'),
			last_name: Yup.string().required('Required'),
		}),
		onSubmit: async (values, { resetForm }) => {
			try {
				const response = await axios.post(
					'https://rockmanapi.nexlogssolutions.com/api/register-admin/',
					{
						username: values.username,
						password: values.password,
						email: values.email,
						phone_no: values.phone_no,
						first_name: values.first_name,
						last_name: values.last_name,
						is_staff: true,
					},
					{
						headers: {
							Authorization: `Token ${token}`,
							'Content-Type': 'application/json',
						},
					}
				);
				// const response = await axios.post(
				// 	'http://127.0.0.1:8000/api/register-admin/',
				// 	{
				// 		username: values.username,
				// 		password: values.password,
				// 		email: values.email,
				// 		phone_no: values.phone_no,
				// 		first_name: values.first_name,
				// 		last_name: values.last_name,
				// 		is_staff: true,
				// 	},
				// 	{
				// 		headers: {
				// 			Authorization: `Token ${token}`,
				// 			'Content-Type': 'application/json',
				// 		},
				// 	}
				// );

				// if (response.status === 200) {
				// 	sessionStorage.setItem('Token', response.data.token);
				// 	navigate('/login');
				// }
			} catch (err) {
				if (err.response && err.response.data) {
					let errorMessage = '';

					// Check if the error response contains email and username errors
					if (err.response.data.email) {
						errorMessage += `Email: ${err.response.data.email.join(', ')} `;
					}
					if (err.response.data.username) {
						errorMessage += `Username: ${err.response.data.username.join(', ')} `;
					}

					// Display the concatenated error message
					alert(errorMessage);
				} else {
					console.log('Error:', err);
					alert('An error occurred. Please try again.');
				}
			}
			resetForm();
		},
	});

	const handleLoginNavigation = () => {
		navigate('/login');
	};

	return (
		<Grid
			container
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'end',
				margin: 'auto',
				padding: 0,
				width: '100%',
			}}
		>
			<Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
				<Box sx={{ width: '100%', maxWidth: 150 }}>
					<Typography variant="h4" sx={{}}>
						Welcome
					</Typography>
				</Box>
			</Grid>
			<Grid item sm={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<form style={{ width: '30%' }} onSubmit={formik.handleSubmit}>
					<Grid item md={12} sx={{ paddingLeft: '0 !important' }}>
						<TextField
							size="small"
							margin="dense"
							id="first_name"
							label="First Name"
							fullWidth
							variant="outlined"
							value={formik.values.first_name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.first_name && formik.errors.first_name}
						/>
					</Grid>
					<Grid item md={12} sx={{ paddingLeft: '0 !important' }}>
						<TextField
							size="small"
							margin="dense"
							id="last_name"
							label="Last Name"
							fullWidth
							variant="outlined"
							value={formik.values.last_name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.last_name && formik.errors.last_name}
						/>
					</Grid>
					<Grid item md={12}>
						<TextField
							size="small"
							margin="dense"
							id="username"
							label="Username"
							fullWidth
							variant="outlined"
							value={formik.values.username}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.username && formik.errors.username}
						/>
					</Grid>
					<Grid item md={12} sx={{ paddingLeft: '0 !important' }}>
						<TextField
							size="small"
							margin="dense"
							type="email"
							id="email"
							label="Email"
							fullWidth
							variant="outlined"
							value={formik.values.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.email && formik.errors.email}
						/>
					</Grid>
					<Grid item md={12} sx={{ paddingLeft: '0 !important' }}>
						<TextField
							size="small"
							margin="dense"
							id="phone_no"
							label="Phone"
							fullWidth
							variant="outlined"
							value={formik.values.phone_no}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.phone_no && formik.errors.phone_no}
						/>
					</Grid>

					<Grid item md={12} sx={{ paddingLeft: '0 !important' }}>
						<TextField
							size="small"
							margin="dense"
							type="password"
							id="password"
							label="password"
							fullWidth
							variant="outlined"
							value={formik.values.password}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.password && formik.errors.password}
						/>
					</Grid>
					<Grid item md={12} sx={{ paddingLeft: '0 !important' }}>
						<TextField
							size="small"
							margin="dense"
							type="password"
							id="confirm_password"
							label="Confirm Password"
							fullWidth
							variant="outlined"
							value={formik.values.confirm_password}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.confirm_password && formik.errors.confirm_password}
						/>
					</Grid>
					<Button
						sx={{ width: '100%', height: '2.9rem', marginTop: '.8rem' }}
						variant="contained"
						type="submit"
					>
						Register
					</Button>
				</form>
				<Divider sx={{ my: 3, width: '30%' }} textAlign="center">
					OR
				</Divider>
				<Button sx={{ width: '30%', height: '2.9rem' }} variant="outlined" onClick={handleLoginNavigation}>
					Login
				</Button>
			</Grid>
		</Grid>
	);
};

export default Register;
