import React, { useEffect, useContext } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import RockmanLogo from '../img/rockmanlogisticslogo.jpeg';
import { makeStyles } from '@mui/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import { menuItem } from './MenuList';
import { useNavigate } from 'react-router-dom';
import TransferDropMenu from './TransferDropMenu';
import ReportDropDownMenu from './ReportDropDownMenu'
import AuthContext from './context/AuthProvider';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	imgSize: { width: 55, height: 50, objectFit: 'contain' },
	imgContainer: {
		width: '20%',
		display: 'flex',
	},
	iconSpan: {
		fontSize: 'medium',
		paddingLeft: '.2rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem', // Smaller font size on small devices
		},
	},
	bgColor: {
		backgroundColor: '#FD9A24 !important',
	},
	mobileDropdownContainer: {
		backgroundColor: '#FD9A24 !importants',
	},
}));

export default function Navbar() {
	const { currencyRate, setCurrencyRate } = useContext(AuthContext);

	const testv = sessionStorage.getItem('Token');
	const UserFromStorage = JSON.parse(sessionStorage.getItem('User'));


	// const [anchorEl, setAnchorEl] = React.useState(null);
	const [isOpen, setIsOpen] = React.useState(false);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	const navigate = useNavigate();
	// const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
		setIsOpen(false);
	};
	const handleMobileMenuOpen = (event) => {
		setIsOpen(false);
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const menuId = 'primary-search-account-menu';

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
			className={`test3 ${isOpen ? 'mobileDropdownDisappear' : ''}`}
		>
			{menuItem.map((item) => {
				if (item.test === 'Payment') {
					return (
						<div key={item.test}>
							<MenuItem
								key={item.test}
								onClick={() => {
									navigate(item.path);
									handleMobileMenuClose();
								}}
							>
								<p>{item.test}</p>
							</MenuItem>
							<div onClick={() => setIsOpen(true)}>
								<TransferDropMenu />
							</div>
						</div>
					);
				}
				if (item.test === 'Report') {
					return (
						<div key={item.test}>
							<MenuItem
								key={item.test}
								onClick={() => {
									navigate(item.path);
									handleMobileMenuClose();
								}}
							>
								<p>{item.test}</p>
							</MenuItem>
							<div onClick={() => setIsOpen(true)}>
								<ReportDropDownMenu />
							</div>
						</div>
					);
				}
				return (
					<MenuItem
						key={item.test}
						onClick={() => {
							navigate(item.path);
							handleMobileMenuClose();
						}}
					>
						<p>{item.test}</p>
					</MenuItem>
				);
			})}
		</Menu>
	);
	const classes = useStyles();
	useEffect(() => {
		axios
			.get('https://rockmanapi.nexlogssolutions.com/api/last-currency-rate/')
			// .get('http://127.0.0.1:8000/api/last-currency-rate/')
			.then((response) => {
				setCurrencyRate(response.data.exchange_rate);
			})
			.catch((err) => {
				alert(err.message);
			});
	}, [currencyRate]);

	const LogoutPost = async () => {
		try {
			const response = await axios.post('https://rockmanapi.nexlogssolutions.com/api/logoutall/', null, {
				headers: {
					Authorization: `Token ${testv}`,
					'Content-Type': 'application/json',
				},
			});
			// const response = await axios.post('http://127.0.0.1:8000/api/logoutall/', null, {
			// 	headers: {
			// 		Authorization: `Token ${testv}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// });
			if (response.status == 204) {
				sessionStorage.setItem('Token', '');
				navigate('/login');
			}
		} catch (err) {
			if (err.response && err.response.status === 401) {
				sessionStorage.setItem('Token', '');
				navigate('/login');
			} else {
				console.log('err', err);
				alert(err.message);
			}
		}
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static" classes={{ root: classes.bgColor }}>
				<Toolbar>
					<div className={classes.imgContainer}>
						<img src={RockmanLogo} className={classes.imgSize} />
					</div>
					<Typography
						sx={{
							color: '#333',
							'@media (max-width: 600px)': {
								display: 'none',
							},
						}}
					>
						Currency Rate :
					</Typography>
					<Typography
						sx={{
							color: '#333',
							marginLeft: 1,
							'@media (max-width: 600px)': {
								fontSize: '0.8rem',
							},
						}}
					>
						{' '}
						{currencyRate}{' '}
					</Typography>
					<Box sx={{ flexGrow: 1, width: '5%' }} />
					{testv == null || testv == '' ? null : (
						<Box sx={{ width: '20%', display: { xs: 'flex' }, justifyContent: 'flex-end' }}>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls={menuId}
								aria-haspopup="true"
								onClick={handleProfileMenuOpen}
								color="inherit"
								sx={{
									padding: { xs: '4px' },
									'& svg': {
										fontSize: { xs: '1rem' },
									},
								}}
							>
								<AccountCircle />
								<span className={classes.iconSpan}>{UserFromStorage && UserFromStorage.username}</span>
							</IconButton>

							<IconButton
								onClick={LogoutPost}
								size="large"
								aria-label="show 17 new notifications"
								color="inherit"
								sx={{
									padding: { xs: '4px', sm: '8px' }, // Smaller padding on small devices
									'& svg': {
										fontSize: { xs: '1rem', sm: '2rem' }, // Smaller icon size on small devices
									},
								}}
							>
								<LogoutIcon /> <span className={classes.iconSpan}>logout</span>
							</IconButton>
						</Box>
					)}
					<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							size="large"
							aria-label="show more"
							aria-controls={mobileMenuId}
							aria-haspopup="true"
							onClick={handleMobileMenuOpen}
							color="inherit"
						>
							<MoreIcon />
						</IconButton>
					</Box>
				</Toolbar>
			</AppBar>
			<Box className={classes.mobileDropdownContainer}>{renderMobileMenu}</Box>
		</Box>
	);
}
