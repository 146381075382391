import React from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import CustomersTable from '../components/CustomersTable';
import { useNavigate } from 'react-router-dom';
import DebtorsList from '../components/DebtorsList';
import CreditorsList from '../components/CreditorsList';
import Sidebar from '../components/Sidebar';

const useStyles = makeStyles((theme) => ({
	item: {
		paddingTop: '0 !important',
	},
	searchNButtonContainer: {
		display: 'block !important',
		marginBlock: '1.5rem',
		marginLeft: '1.5rem',
		marginInline: 0,
	},
	inputMargin: {
		marginLeft: '1.2rem',
	},
}));

function HomePage() {
	const [open, setOpen] = React.useState(false);
	const [openDebtors, setOpenDebtors] = React.useState(false);
	const [openCreditors, setOpenCreditors] = React.useState(false);
	const [customer, setCustomers] = React.useState([]);
	const [debtors, setDebtors] = React.useState(null);
	const [creditors, setCreditors] = React.useState([]);
	const [error, setError] = React.useState(null);
	const [selectedUsername, setSelectedUsername] = React.useState('');
	const [filteredData, setFilteredData] = React.useState([]);
	const [nextPage, setNextPage] = React.useState(null);
	const [prevPage, setPrevPage] = React.useState(null);

	let navigate = useNavigate();
	const token = sessionStorage.getItem('Token');

	const fetchData = async (url) => {
		try {
			const response = await axios.get(url, {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'application/json',
				},
			});

			const { data } = response;
			setCustomers(data);
			// setNextPage(data.next);
			// setPrevPage(data.previous);
		} catch (error) {
			setError(error);
		}
	};

	React.useEffect(() => {
		if (token == null || token == '') {
			navigate('/login');
		}
	});
	React.useEffect(() => {
		// fetchData('http://127.0.0.1:8000/api/customerlist/');
		fetchData('https://rockmanapi.nexlogssolutions.com/api/customerlist/');
	}, [open]);
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickOpenDebtors = () => {
		setOpenDebtors(true);
		function getDebtors() {
			axios
				.get('https://rockmanapi.nexlogssolutions.com/api/customerswithnegativebalance/', {
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				})
				// .get('http://127.0.0.1:8000/api/customerswithnegativebalance/', {
				// 	headers: {
				// 		Authorization: `Token ${token}`,
				// 		'Content-Type': 'application/json',
				// 	},
				// })
				.then((res) => {
					setDebtors(res.data);
				})
				.catch((error) => {
					setError(error);
				});
		}
		getDebtors();
	};

	const handleCloseOpenDebtors = () => {
		setOpenDebtors(false);
	};

	const handleClickOpenCreditor = () => {
		setOpenCreditors(true);
		function getCreditors() {
			axios
				.get('https://rockmanapi.nexlogssolutions.com/api/customerswithpositivebalance/', {
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				})
				// .get('http://127.0.0.1:8000/api/customerswithpositivebalance/', {
				// 	headers: {
				// 		Authorization: `Token ${token}`,
				// 		'Content-Type': 'application/json',
				// 	},
				// })
				.then((res) => {
					setCreditors(res.data);
				})
				.catch((error) => {
					setError(error);
				});
		}
		getCreditors();
	};

	const handleCloseOpenCreditor = () => {
		setOpenCreditors(false);
	};
	const PostCustomer = async (values) => {
		const customerValue = {
			user_id: {
				password: 'rockmam',
				username: values.username,
				first_name: values.firstName,
				last_name: values.lastName,
				email: values.email,
				phone_no: values.phoneNumber,
			},
			company_name: values.companyName,
			account_balance: 0.0,
			dept: 0.0,
			tranfer_account_balance: 0,
			freight_account_balance: 0,
		};
		try {
			const response = await axios.post(
				'https://rockmanapi.nexlogssolutions.com/api/customerlist/',
				customerValue,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			// const response = await axios.post('http://127.0.0.1:8000/api/customerlist/', customerValue, {
			// 	headers: {
			// 		Authorization: `Token ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// });
			if (response.status == 201) {
				setOpen(false);
				alert('Customer Added Successfully');
			} else {
				alert(response.data);
			}
		} catch (err) {
			alert(err.response.data.error);
		}
	};

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			companyName: '',
			phoneNumber: '',
			username: '',
			password: '',
		},
		validationSchema: Yup.object({
			firstName: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
			lastName: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
			companyName: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
			email: Yup.string()
				.email('Must be a valid email')
				.max(50, 'Must be 15 characters or less')
				.required('Email is required'),
			phoneNumber: Yup.string()
				.min(9, 'Must be exactly 10 numbers')
				.max(9, 'Must be 10 exactly 10 numbers')
				.required('Required'),
			username: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
		}),
		onSubmit: (values, { resetForm }) => {
			PostCustomer(values);
			resetForm();
		},
	});

	const fetchNextPage = () => {
		if (nextPage) {
			fetchData(nextPage);
		}
	};

	const fetchPrevPage = () => {
		if (prevPage) {
			fetchData(prevPage);
		}
	};

	const classes = useStyles();
	return (
		<Grid container>
			<Grid id="sidebarContainer" item md={2}>
				<Sidebar />
			</Grid>
			<Grid
				item
				md={10}
				sx={{
					'@media (max-width: 600px)': {
						display: 'contents',
					},
				}}
			>
				<Grid id="searchButtonControl" container className={classes.searchNButtonContainer}>
					<Grid item sm={12} md={4}>
						<TextField
							sx={{ maxWidth: '100%', marginLeft: { xs: 0, sm: '1.2rem' } }}
							size="small"
							margin="dense"
							id="searchbar"
							label="Search User"
							fullWidth
							variant="outlined"
							value={selectedUsername}
							onChange={(e) => {
								// (e.target.value);
								const searchWord = e.target.value;
								setSelectedUsername(searchWord);
								const newFilter = customer.filter((value) => {
									return value.user_id.username.toLowerCase().includes(searchWord.toLowerCase());
								});
								if (searchWord === '') {
									setFilteredData([]);
								} else {
									setFilteredData(newFilter);
								}
							}}
						/>
					</Grid>
					<Grid item sm={12} md={1}></Grid>
					<Grid item sm={12} md={7} xs={12} id="buttonContainer2" className="buttonContainer paddingTops">
						<Grid item sm={12} md={12} lg={12} className="paddingTops">
							<Button
								sx={{ width: '100%' }}
								size="medium"
								variant="contained"
								startIcon={<AddOutlinedIcon />}
								onClick={handleClickOpen}
							>
								Add Customer
							</Button>
						</Grid>
						<Grid item sm={12} md={12} lg={12} className="paddingTops">
							<Button
								sx={{ width: '100%' }}
								size="medium"
								variant="contained"
								startIcon={<FormatListBulletedIcon />}
								onClick={handleClickOpenDebtors}
							>
								Debtors
							</Button>
							<DebtorsList
								openDebtors={openDebtors}
								onCloseDebtors={handleCloseOpenDebtors}
								debtors={debtors}
							/>
						</Grid>
						<Grid item sm={12} md={12} lg={12} className="paddingTops">
							<Button
								sx={{ width: '100%' }}
								size="medium"
								variant="contained"
								startIcon={<FormatListBulletedIcon />}
								onClick={handleClickOpenCreditor}
							>
								Creditor
							</Button>
							<CreditorsList
								openCreditors={openCreditors}
								onCloseCreditors={handleCloseOpenCreditor}
								creditors={creditors}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Dialog open={open}>
					<DialogTitle
						sx={{ textAlign: 'center', fontSize: { xs: 'large', sm: 'xx-large' }, paddingBottom: 0 }}
					>
						Customer Registration
					</DialogTitle>
					<DialogContent>
						<Box style={{ paddingTop: 5 }}>
							<form onSubmit={formik.handleSubmit}>
								<Grid
									container
									spacing={2}
									sx={{ display: { xs: 'flex' }, justifyContent: { xs: 'center' } }}
								>
									<Grid item sm={6} sx={{ paddingTop: '10px !important' }}>
										<TextField
											sx={{
												'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
												'& .MuiFormHelperText-root': { lineHeight: 0 },
											}}
											helperText={
												formik.touched.firstName && formik.errors.firstName ? (
													<p>{formik.errors.firstName}</p>
												) : null
											}
											size="small"
											margin="dense"
											id="firstName"
											label="First name"
											fullWidth
											variant="outlined"
											value={formik.values.firstName}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.firstName && formik.errors.firstName}
										/>
									</Grid>
									<Grid item sm={6} sx={{ paddingTop: '10px !important' }}>
										<TextField
											sx={{
												'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
												'& .MuiFormHelperText-root': { lineHeight: 0 },
											}}
											helperText={
												formik.touched.lastName && formik.errors.lastName ? (
													<p>{formik.errors.lastName}</p>
												) : null
											}
											size="small"
											margin="dense"
											id="lastName"
											label="Last name"
											fullWidth
											variant="outlined"
											value={formik.values.lastName}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.lastName && formik.errors.lastName}
										/>
									</Grid>
									<Grid item sm={6} sx={{ paddingTop: '10px !important' }}>
										<TextField
											sx={{
												'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
												'& .MuiFormHelperText-root': { lineHeight: 0 },
											}}
											helperText={
												formik.touched.companyName && formik.errors.companyName ? (
													<p>{formik.errors.companyName}</p>
												) : null
											}
											size="small"
											margin="dense"
											id="companyName"
											label="Company Name"
											fullWidth
											variant="outlined"
											value={formik.values.companyName}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.companyName && formik.errors.companyName}
										/>
									</Grid>
									<Grid item sm={6} sx={{ paddingTop: '10px !important' }}>
										<TextField
											sx={{
												'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
												'& .MuiFormHelperText-root': { lineHeight: 0 },
											}}
											helperText={
												formik.touched.email && formik.errors.email ? (
													<p>{formik.errors.email}</p>
												) : null
											}
											size="small"
											margin="dense"
											id="email"
											label="Email"
											type="email"
											fullWidth
											variant="outlined"
											value={formik.values.email}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.email && formik.errors.email}
										/>
									</Grid>
									<Grid item sm={12} sx={{ paddingTop: '10px !important' }}>
										<TextField
											sx={{
												'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
												'& .MuiFormHelperText-root': { lineHeight: 0 },
											}}
											helperText={
												formik.touched.phoneNumber && formik.errors.phoneNumber ? (
													<p>{formik.errors.phoneNumber}</p>
												) : null
											}
											type="number"
											size="small"
											margin="dense"
											id="phoneNumber"
											label="Phone Number"
											fullWidth
											variant="outlined"
											value={formik.values.phoneNumber}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.phoneNumber && formik.errors.phoneNumber}
										/>
									</Grid>
									<Grid item sm={12} sx={{ paddingTop: '10px !important' }}>
										<TextField
											sx={{
												'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
												'& .MuiFormHelperText-root': { lineHeight: 0 },
											}}
											helperText={
												(formik.touched.username && formik.errors.username) || true ? (
													<p>{formik.errors.username}</p>
												) : null
											}
											size="small"
											margin="dense"
											id="username"
											label="Username"
											fullWidth
											variant="outlined"
											value={formik.values.username}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.username && formik.errors.username}
										/>
									</Grid>
									<Grid item sm={12} sx={{ paddingTop: '10px !important' }}>
										<TextField
											sx={{
												'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
												'& .MuiFormHelperText-root': { lineHeight: 0 },
											}}
											helperText={
												formik.touched.password && formik.errors.password ? (
													<p>{formik.errors.password}</p>
												) : null
											}
											type="password"
											hiddenLabel
											size="small"
											margin="dense"
											id="password"
											label="password"
											fullWidth
											variant="outlined"
											value={'test1!@'}
											// onChange={formik.handleChange}
											// onBlur={formik.handleBlur}
											// error={formik.touched.password && formik.errors.password}
										/>
									</Grid>
								</Grid>
								<Box
									sx={{
										gap: {xs:'1rem', sm:'6rem'},
										display: 'flex',
										justifyContent: 'center !important',
										paddingBlock: '1rem',
									}}
								>
									<Button
										sx={{ width: { xs: '40%', sm: '20%' } }}
										variant="contained"
										onClick={handleClose}
									>
										Cancel
									</Button>
									<Button
										sx={{ width: { xs: '40%', sm: '20%' }, marginLeft: '0 !important' }}
										variant="contained"
										type="submit"
									>
										Submit
									</Button>
								</Box>
							</form>
						</Box>
					</DialogContent>
				</Dialog>
				<CustomersTable filterCustomers={filteredData} customers={customer} />
				{/* <Box display="flex" justifyContent="center" gap={3} mt={2}>
					<Button variant="contained" onClick={fetchPrevPage} disabled={!prevPage}>
						Prev
					</Button>
					<Button variant="contained" onClick={fetchNextPage} disabled={!nextPage}>
						Next
					</Button>
				</Box> */}
			</Grid>
		</Grid>
	);
}

export default HomePage;
