import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Grid from '@mui/material/Grid';
import TransferPaymentListTable from '../components/TransferPaymentListTable';
import axios from 'axios';
import AddTransferForm from '../components/AddTransferForm';
import Sidebar from '../components/Sidebar';

const useStyles = makeStyles((theme) => ({
	item: {
		paddingTop: '0 !important',
	},
	searchNButtonContainer: { marginBlock: '1.5rem' },
}));

const Transfer = () => {
	const [openTP, setOpenTP] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	// const [error, setError] = React.useState(false);
	const [transferPayment, setTransferPayment] = React.useState([]);
	const [users, setUsers] = React.useState([]);
	const [selectedUsername, setSelectedUsername] = React.useState('');
	const [filteredData, setFilteredData] = React.useState([]);
	const token = sessionStorage.getItem('Token');

	const fetchData = () => {
		const customerList = 'https://rockmanapi.nexlogssolutions.com/api/customerlist/';
		// const customerList = 'http://127.0.0.1:8000/api/customerlist/';
		const transferList = 'https://rockmanapi.nexlogssolutions.com/api/customertranfer/';
		// const transferList = 'http://127.0.0.1:8000/api/customertranfer/';

		const getCustomerList = axios.get(customerList, {
			headers: {
				Authorization: `Token ${token}`,
				'Content-Type': 'application/json',
			},
		});
		const getTransferList = axios.get(transferList, {
			headers: {
				Authorization: `Token ${token}`,
				'Content-Type': 'application/json',
			},
		});

		axios.all([getCustomerList, getTransferList]).then(
			axios.spread((...allData) => {
				const allDataCustomerList = allData[0].data;
				const allDataTransferPaymentList = allData[1].data;

				setUsers(allDataCustomerList);
				setTransferPayment(allDataTransferPaymentList);
			})
		);
	};

	React.useEffect(() => {
		fetchData();
	}, [openTP]);
	const classes = useStyles();

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		// window.location.reload();
	};

	return (
		<Grid container>
			<Grid id="sidebarContainer" item md={2}>
				<Sidebar />
			</Grid>
			<Grid
				item
				md={10}
				sx={{
					'@media (max-width: 600px)': {
						display: 'contents',
					},
				}}
			>
				<Grid container className={classes.searchNButtonContainer} sx={{justifyContent:'center', alignItems:'center'}}>
					<Grid item sm={6} sx={{ paddingLeft: { xs: 0, sm: '1.5rem' } }}>
						<TextField
							sx={{ maxWidth: '100%', marginLeft: { xs: 0, sm: '1.2rem' } }}
							size="small"
							margin="dense"
							id="searchbar"
							label="Search User"
							fullWidth
							variant="outlined"
							value={selectedUsername}
							onChange={(e) => {
								// (e.target.value);
								const searchWord = e.target.value;
								setSelectedUsername(searchWord);
								const newFilter = transferPayment.filter((value) => {
									return value.customer_id.user_id.username
										.toLowerCase()
										.includes(searchWord.toLowerCase());
								});
								if (searchWord === '') {
									setFilteredData([]);
								} else {
									setFilteredData(newFilter);
								}
							}}
						/>
					</Grid>
					<Grid
						id="tranferButtons"
						item
						sm={6}
						sx={{
							display: 'flex',
							justifyContent: 'end',
							gap: '2rem',
						}}
					>
						<Button variant="contained" startIcon={<AddOutlinedIcon />} onClick={handleClickOpen}>
							Make Transfer
						</Button>
						<AddTransferForm open={open} onClose={handleClose} users={users} setOpenTP={setOpenTP} />
					</Grid>
				</Grid>
				<TransferPaymentListTable filterTransfer={filteredData} TransferPayments={transferPayment} />
			</Grid>
		</Grid>
	);
};

export default Transfer;
